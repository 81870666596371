<template>
  <div class="pos-order">
    <v-layout class="margin-unset w-100">
      <v-flex md4 class="p-0 pos-order-left btx-border-right">
        <v-layout class="cyan">
          <v-flex class="px-4"
            ><v-btn v-on:click="goBack()" icon large color="white" tile depressed
              ><v-icon large>mdi-arrow-left-bold-circle-outline</v-icon></v-btn
            ></v-flex
          >
          <v-flex v-if="bill_split" class="pos-bill-split d-flex justify-end">
            <v-layout>
              <v-flex v-if="bill_count[1]">
                <v-btn
                  depressed
                  tile
                  color="cyan h-100 white--text w-100"
                  v-on:click="bill_tab = 'table_tab_1'"
                  :class="{
                    'darken-3': bill_tab == 'table_tab_1',
                    'darken-1': bill_tab != 'table_tab_1',
                  }"
                >
                  <v-badge color="teal darken-4" :content="bill_count[1]">
                    <span class="normal-text-2">Bill 1</span>
                  </v-badge>
                </v-btn>
              </v-flex>
              <v-flex v-if="bill_count[2]">
                <v-btn
                  depressed
                  tile
                  color="cyan h-100 white--text w-100"
                  v-on:click="bill_tab = 'table_tab_2'"
                  :class="{
                    'darken-3': bill_tab == 'table_tab_2',
                    'darken-1': bill_tab != 'table_tab_2',
                  }"
                >
                  <v-badge color="teal darken-4" :content="bill_count[2]">
                    <span class="normal-text-2">Bill 2</span>
                  </v-badge>
                </v-btn>
              </v-flex>
              <v-flex v-if="bill_count[1]">
                <v-btn
                  depressed
                  tile
                  color="cyan h-100 white--text w-100"
                  v-on:click="bill_tab = 'table_tab_3'"
                  :class="{
                    'darken-3': bill_tab == 'table_tab_3',
                    'darken-1': bill_tab != 'table_tab_3',
                  }"
                >
                  <v-badge color="teal darken-4" :content="bill_count[3]">
                    <span class="normal-text-2">Bill 3</span>
                  </v-badge>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-else class="pos-bill-split d-flex justify-end">
            <p class="m-0 normal-text-2 px-8 white--text p-2">{{ table.name }}</p>
          </v-flex>
        </v-layout>
        <div class="pos-order-items" v-if="table_items[bill_tab].length">
          <table class="w-100">
            <template v-for="(row, index) in table_items[bill_tab]">
              <tr
                :key="`table-item-${index}`"
                class="table-item"
                :class="getAlternativeClass(index)"
                :id="`table-item-${index}`"
              >
                <td class="py-2 pl-2" width="60%">
                  <div class="d-flex align-center justify-center">
                    <div>
                      <v-btn
                        :disabled="delete_loading"
                        v-on:click="deleteTableItem(row)"
                        class="mx-2"
                        icon
                        large
                        color="red lighten-1"
                        ><v-icon large>mdi-delete-forever</v-icon></v-btn
                      >
                    </div>
                    <div>
                      <v-chip
                        label
                        x-small
                        color="cyan darken-3"
                        text-color="white"
                        class="h-20px justify-center"
                        >{{ row.barcode }}</v-chip
                      >
                      <p class="normal-text-2 mt-1 m-0 ellipsis-4-line">{{ row.name }}</p>
                    </div>
                  </div>
                  <div class="d-block"></div>
                </td>
                <td align="center" width="20%" class="py-4 px-2">
                  <v-layout class="align-center">
                    <v-flex>
                      <v-btn
                        v-on:click="updateQty(index, 'decrease')"
                        icon
                        color="red lighten-1"
                        class="bg-grey-lighten-4 btx-border"
                        ><v-icon class="v-icon-28px">mdi-minus</v-icon></v-btn
                      >
                    </v-flex>
                    <v-flex class="mx-2 normal-text-3">x{{ row.quantity }}</v-flex>
                    <v-flex>
                      <v-btn
                        v-on:click="updateQty(index, 'increase')"
                        icon
                        color="green lighten-1"
                        class="bg-grey-lighten-4 btx-border"
                        ><v-icon class="v-icon-28px">mdi-plus</v-icon></v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-chip
                    color="cyan"
                    text-color="white"
                    class="btx-tile-chip mt-4 h-30px normal-text-2 w-100 justify-center"
                  >
                    {{ getItemTotal(index) }}
                  </v-chip>
                </td>
              </tr>
              <template v-if="row.add_ons && row.add_ons.length">
                <tr
                  class="table-item-addon"
                  :class="getAlternativeClass(index)"
                  v-for="(arow, aindex) in row.add_ons"
                  :key="`table-item-addon-${index}-${aindex}`"
                >
                  <td colspan="2" :key="aindex">
                    <v-layout class="mx-3 py-2 align-center">
                      <v-flex md8 class="normal-text-1 ellipsis-4-line"
                        >{{ arow.name }}
                        <template v-if="arow.quantity > 1">x{{ arow.quantity }} </template>:</v-flex
                      >
                      <v-flex class="text-center normal-text-1-bold normal-text-1">{{
                        formatCurrency(arow.total)
                      }}</v-flex>
                      <v-flex class="text-right">
                        <v-btn
                          icon
                          color="red lighten-1"
                          class="addon-action"
                          v-on:click="removeAddOn(index, aindex)"
                        >
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </td>
                </tr>
              </template>
              <template v-if="row.remark">
                <tr
                  class="table-item-addon"
                  :class="getAlternativeClass(index)"
                  :key="`table-item-remark-${index}`"
                  :id="`table-item-remark-${index}`"
                >
                  <td colspan="2">
                    <v-layout class="mx-3 py-2 align-center">
                      <v-flex md9 class="normal-text-1"
                        >Remarks:
                        <span class="font-weight-bolder ml-2">{{ row.remark }}</span></v-flex
                      >
                      <v-flex md3 class="d-flex justify-space-between">
                        <v-btn
                          icon
                          color="cyan darken-3"
                          class="mr-2 addon-action"
                          v-on:click="remarkEdit(index)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="red lighten-1"
                          class="ml-2 addon-action"
                          v-on:click="remarkClear(index)"
                        >
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </td>
                </tr>
              </template>
            </template>
            <tr
              v-for="(trow, tindex) in table_add_ons"
              class="table-item"
              :key="`table-addon-${tindex}`"
              :id="`table-addon-${tindex}`"
            >
              <td class="py-2 pl-2" width="60%">
                <p class="normal-text-2 mt-1 m-0 ellipsis-4-line">
                  {{ trow.name }} <span v-if="trow.quantity > 1">x{{ trow.quantity }}</span
                  >:
                </p>
              </td>
              <td align="center" width="20%" class="py-4 px-2">
                <v-chip
                  color="cyan"
                  text-color="white"
                  class="btx-tile-chip normal-text-2 w-100 justify-center"
                >
                  {{ formatCurrency(trow.total) }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="table_order_remark" class="table-item table-order-remark" id="table-remark">
              <td colspan="2">
                <v-layout class="mx-3 py-2 align-center">
                  <v-flex md9 class="normal-text-2"
                    >Remarks:
                    <span class="font-weight-bolder ml-2">{{ table_order_remark }}</span></v-flex
                  >
                  <v-flex md3 class="d-flex justify-space-between">
                    <v-btn
                      icon
                      color="cyan darken-3"
                      class="mr-2 addon-action"
                      v-on:click="remarkOrderEdit()"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="red lighten-1"
                      class="ml-2 addon-action"
                      v-on:click="remarkOrderClear()"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="w-100 text-color text-center mt-20 pos-no-item-selected">
          <template v-if="tableLoading">
            <p class="m-0 normal-text-3 text--secondary text-center">Loading...</p>
            <v-progress-linear
              color="cyan"
              class="w-80 mx-auto mt-10"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </template>
          <template v-else>
            <p class="m-0 normal-text-3 text--secondary text-center">No Item Selected</p>
            <p class="m-0 normal-text-1 text--secondary text-center">
              Please select item from menu
            </p>
          </template>
          <v-img
            contain
            width="150"
            :src="$assetURL('media/logos/logo-tt.png')"
            class="brand-logo mx-auto mt-10"
            alt="brand-logo"
          ></v-img>
        </div>
        <div class="pos-order-total p-2 grey lighten-3">
          <v-layout class="mb-3 mt-2">
            <v-flex>
              <p class="m-0 normal-text-3">Total ({{ table_items[bill_tab].length }})</p>
            </v-flex>
            <v-flex class="text-right">
              <p class="m-0 normal-text-3">{{ totalAmount() }}</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md6 class="mr-2">
              <v-btn
                tile
                :disabled="formLoading || !table_items[bill_tab].length"
                :loading="formLoading"
                depressed
                class="w-100"
                color="green lighten-1 white--text"
                ><v-icon>mdi-chevron-left</v-icon> Send to Kitchen</v-btn
              >
            </v-flex>
            <v-flex md6 class="ml-2 text-right">
              <v-btn
                tile
                :disabled="formLoading || !table_items[bill_tab].length"
                :loading="formLoading"
                v-on:click="pushToCheckout()"
                depressed
                class="w-100"
                color="cyan white--text"
                >{{ totalAmount() }} <v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex md6 class="p-0 pos-items">
        <div class="w-100">
          <div class="w-100">
            <v-text-field
              depressed
              tile
              placeholder="Search Item"
              solo
              id="search-item"
              @focus="keyboardString(search_item, 'search-item')"
              hide-details
              dense
              v-model="search_item"
              ref="search-item"
              color="cyan"
              flat
              clearable
              prepend-inner-icon="mdi-cloud-search"
              append-outer-icon="mdi-keyboard-variant"
              v-on:click:append-outer="keyboardString(search_item, 'search-item')"
              class="w-100 show-keyboard pos-item-search"
            ></v-text-field>
          </div>
          <template v-if="remark_block">
            <v-form ref="item-remark-form" v-model.trim="item_form_valid" lazy-validation>
              <v-layout>
                <v-flex md10>
                  <v-textarea
                    depressed
                    flat
                    tile
                    color="cyan"
                    auto-grow
                    rows="10"
                    dense
                    :rules="[vrules.maxLength(remark, 'Remark', 50)]"
                    v-model="remark"
                    @focus="keyboardString(remark, 'remark')"
                    class="h-100 w-100 show-keyboard pos-item-search"
                    append-outer-icon="mdi-keyboard-variant"
                    v-on:click:append-outer="keyboardString(remark, 'remark')"
                    placeholder="Enter Remarks"
                    solo
                    id="remark"
                    ref="remark"
                  ></v-textarea>
                </v-flex>
                <v-flex md2>
                  <v-layout column class="h-100 white-solid-border-right">
                    <v-flex md4
                      ><v-btn
                        :disabled="!item_form_valid"
                        v-on:click="remarkAction('save')"
                        depressed
                        tile
                        class="w-100 h-100"
                        color="cyan white--text"
                        >Save</v-btn
                      ></v-flex
                    >
                    <v-flex md4
                      ><v-btn
                        v-on:click="remarkAction('clear')"
                        depressed
                        tile
                        class="w-100 h-100"
                        color="cyan darken-3 white--text"
                        >Clear</v-btn
                      ></v-flex
                    >
                    <v-flex md4
                      ><v-btn
                        v-on:click="remarkAction('close')"
                        depressed
                        tile
                        class="w-100 h-100"
                        color="red lighten-1 white--text"
                        >Close</v-btn
                      ></v-flex
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </template>
          <template v-else-if="order_remark_block">
            <v-form ref="order-remark-form" v-model.trim="order_form_valid" lazy-validation>
              <v-layout>
                <v-flex md10>
                  <v-textarea
                    depressed
                    flat
                    tile
                    color="cyan"
                    auto-grow
                    rows="10"
                    dense
                    :rules="[vrules.maxLength(order_remark, 'Order Remark', 50)]"
                    v-model="order_remark"
                    @focus="keyboardString(order_remark, 'order_remark')"
                    class="h-100 w-100 show-keyboard pos-item-search"
                    append-outer-icon="mdi-keyboard-variant"
                    v-on:click:append-outer="keyboardString(order_remark, 'order_remark')"
                    placeholder="Enter Remarks"
                    solo
                    id="order_remark"
                    ref="order_remark"
                  ></v-textarea>
                </v-flex>
                <v-flex md2>
                  <v-layout column class="h-100 white-solid-border-right">
                    <v-flex md4
                      ><v-btn
                        :disabled="!order_form_valid"
                        v-on:click="remarkOrderAction('save')"
                        depressed
                        tile
                        class="w-100 h-100"
                        color="cyan white--text"
                        >Save</v-btn
                      ></v-flex
                    >
                    <v-flex md4
                      ><v-btn
                        v-on:click="remarkOrderAction('clear')"
                        depressed
                        tile
                        class="w-100 h-100"
                        color="cyan darken-3 white--text"
                        >Clear</v-btn
                      ></v-flex
                    >
                    <v-flex md4
                      ><v-btn
                        v-on:click="remarkOrderAction('close')"
                        depressed
                        tile
                        class="w-100 h-100"
                        color="red lighten-1 white--text"
                        >Close</v-btn
                      ></v-flex
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </template>
          <template v-else>
            <div
              v-if="items.length"
              class="d-flex pos-item-list flex-wrap align-content-start justify-center"
            >
              <div
                v-for="(row, index) in items"
                :key="index"
                v-on:click="addItem(row)"
                class="p-2 text-center pos-item cursor-pointer"
              >
                <v-img
                  contain
                  class="mx-auto"
                  :src="row.image"
                  width="160"
                  height="106"
                  :lazy-src="$assetURL('media/misc/no_photo_found.png')"
                ></v-img>
                <v-chip
                  label
                  x-small
                  color="cyan darken-3"
                  text-color="white"
                  class="pos-item-barcode justify-center"
                  >{{ row.barcode }}</v-chip
                >
                <p class="my-2 pos-item-title normal-text-1 text-center">
                  {{ row.name_en }}
                </p>
                <v-layout>
                  <v-flex md6 class="text-left my-auto">
                    <v-chip
                      label
                      x-small
                      color="cyan darken-3"
                      text-color="white"
                      class="justify-center"
                    >
                      {{ formatCurrency(row.final_amount) }}
                    </v-chip>
                  </v-flex>
                  <v-flex md6 class="text-right my-auto">
                    <v-chip
                      label
                      x-small
                      color="cyan darken-3"
                      text-color="white"
                      class="justify-center"
                    >
                      <div class="d-flex align-center">
                        <v-icon x-small class="mr-1">mdi-clock-check-outline</v-icon>
                        {{ row.time_taken }} min
                      </div>
                    </v-chip>
                  </v-flex>
                </v-layout>
              </div>
            </div>
            <div
              v-else-if="add_ons.length"
              class="d-flex pos-item-list flex-wrap align-content-start justify-center"
            >
              <div
                v-for="(row, index) in add_ons"
                :key="index"
                v-on:click="addItemAddOn(row)"
                class="p-2 text-center pos-item cursor-pointer"
              >
                <v-img
                  contain
                  class="mx-auto"
                  :src="row.image || ''"
                  width="160"
                  height="106"
                  :lazy-src="$assetURL('media/misc/no_photo_found.png')"
                ></v-img>
                <p class="my-2 pos-item-title normal-text-1 text-center">
                  {{ row.name }}
                </p>
                <v-layout>
                  <v-flex md6 class="text-left my-auto">
                    <v-chip
                      v-if="row.category != 'remark'"
                      label
                      x-small
                      color="cyan darken-3"
                      text-color="white"
                      class="justify-center"
                    >
                      {{ formatCurrency(row.amount) }}
                    </v-chip>
                  </v-flex>
                  <v-flex md6 class="text-right my-auto">
                    <v-chip
                      label
                      x-small
                      color="cyan darken-3"
                      text-color="white"
                      class="justify-center"
                    >
                      <div class="d-flex align-center">
                        <template v-if="row.type == 'item_add_on'">ITEM</template>
                        <template v-else-if="row.type == 'table_add_on'">ORDER</template>
                      </div>
                    </v-chip>
                  </v-flex>
                </v-layout>
              </div>
            </div>
            <div
              v-else-if="item_loading"
              class="w-100 text-color text-center mt-20 pos-no-item-found"
            >
              <p class="m-0 normal-text-3 text--secondary text-center">Loading...</p>
              <v-progress-linear
                color="cyan"
                class="w-80 mx-auto mt-10"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <v-img
                contain
                width="150"
                :src="$assetURL('media/logos/logo-tt.png')"
                class="brand-logo mx-auto mt-10"
                alt="brand-logo"
              ></v-img>
            </div>
            <div v-else class="w-100 text-color text-center mt-20 pos-no-item-found">
              <p class="m-0 normal-text-3 text--secondary text-center">
                No Item Found in {{ category.name }}
              </p>
              <v-img
                contain
                width="150"
                :src="$assetURL('media/logos/logo-tt.png')"
                class="brand-logo mx-auto mt-10"
                alt="brand-logo"
              ></v-img>
            </div>
          </template>
        </div>
      </v-flex>
      <v-flex md2 class="cyan pos-menu-list p-0">
        <div
          v-for="(row, index) in item_category"
          :key="index"
          v-on:click="getItemByCategory(row)"
          :class="{ active: category.name == row.name }"
          class="pos-menu normal-text-1 px-4 cursor-pointer"
        >
          {{ row.name }}
        </div>
      </v-flex>
    </v-layout>
    <Keyboard
      v-if="show_keyboard"
      v-model="value_string_model"
      v-on:close="keyboardClose()"
    ></Keyboard>
  </div>
</template>
<script>
import Keyboard from "@/view/components/Keyboard";
import { UPDATE_TABLE_ITEMS, GET_ITEMS, GET_TABLE_ITEMS, DELETE_ITEM } from "@/core/lib/pos.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import {
  toNumber,
  isArray,
  isNull,
  cloneDeep,
  sum,
  round,
  head,
  findIndex,
  toSafeInteger,
} from "lodash";
import POSMixin from "@/core/mixins/pos.mixin";

export default {
  mixins: [POSMixin],
  data() {
    return {
      table: {},
      customer: {},
      discountTypeTab: null,
      discountTab: null,
      discountValue: null,
      walkInCustomer: null,
      gift: null,
      table_order_remark: null,
      table_extra_fees: [],
      table_add_ons: [],
      bill_count: {
        1: 0,
        2: 0,
        3: 0,
      },
      bill_split: true,
      table_items: {
        table_tab_1: [],
        table_tab_2: [],
        table_tab_3: [],
      },
      delete_loading: false,
      item_loading: true,
      checkbox: false,
      tableLoading: true,
      formLoading: false,
      remark_block: false,
      order_remark_block: false,
      search_item: null,
      remark: null,
      order_remark: null,
      add_ons: [],
      items: [],
      item_category: [],
      category: null,
      timeout: null,
      timeoutInterval: 300,
      all_selected: true,
      editIndex: null,
      tableId: null,
      item_form_valid: true,
      order_form_valid: true,
      bill_tab: "table_tab_1",
    };
  },
  watch: {
    search_item() {
      this.$nextTick(() => {
        this.searchItems();
      });
    },
  },
  methods: {
    deleteTableItem({ db_id }) {
      this.delete_loading = true;
      const table = this.table.id;
      DELETE_ITEM(table, db_id)
        .then((data) => {
          this.initTable(data);
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    getAlternativeClass(index) {
      if (index % 2 == 0) {
        return "table-item-even";
      }
      return "table-item-odd";
    },
    remarkEdit(index) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        this.editIndex = index;
        this.remark = this.table_items[table_tab][index].remark;
        this.order_remark_block = false;
        this.remark_block = true;
        this.keyboardString(this.remark, "remark");
      }
    },
    remarkOrderEdit() {
      this.order_remark = this.table_order_remark;
      this.remark_block = false;
      this.order_remark_block = true;
      this.keyboardString(this.order_remark, "order_remark");
    },
    remarkClear(index) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        this.table_items[table_tab][index].remark = null;
      }
    },
    remarkOrderClear() {
      this.order_remark = null;
      this.table_order_remark = null;
    },
    remarkAction(type) {
      if (type == "save") {
        if (!this.$refs["item-remark-form"].validate()) {
          return false;
        }

        const table_tab = this.bill_tab;
        if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
          const index = isNull(this.editIndex) ? -1 : toSafeInteger(this.editIndex);
          if (index >= 0) {
            this.table_items[table_tab][index].remark = cloneDeep(this.remark);
          } else {
            for (let i = 0; i < this.table_items[table_tab].length; i++) {
              if (this.table_items[table_tab][i].status) {
                this.table_items[table_tab][i].remark = cloneDeep(this.remark);
              }
            }
          }
          this.remark = null;
          this.editIndex = null;
          this.remark_block = false;
          this.keyboardClose();
        }
      } else if (type == "clear") {
        this.remark = null;
        this.editIndex = null;
      } else if (type == "close") {
        this.remark_block = false;
        this.editIndex = null;
        this.keyboardClose();
      }
    },
    remarkOrderAction(type) {
      if (type == "save") {
        if (!this.$refs["order-remark-form"].validate()) {
          return false;
        }

        this.table_order_remark = this.order_remark;
        this.order_remark_block = false;
        this.keyboardClose();
      } else if (type == "clear") {
        this.order_remark = null;
      } else if (type == "close") {
        this.order_remark_block = false;
        this.keyboardClose();
      }
    },
    removeAddOn(index, aindex) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        const { quantity, amount } = this.table_items[table_tab][index].add_ons[aindex];
        if (quantity <= 1) {
          this.table_items[table_tab][index].add_ons.splice(aindex, 1);
        } else {
          const newQuantity = toSafeInteger(quantity) - 1;
          this.table_items[table_tab][index].add_ons[aindex].quantity = newQuantity;
          this.table_items[table_tab][index].add_ons[aindex].total = round(amount * newQuantity, 2);
        }
      }
    },
    selectAllItem() {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        for (let i = 0; i < this.table_items[table_tab].length; i++) {
          this.table_items[table_tab][i].status = !this.all_selected;
        }
        this.updateSelected();
      }
    },
    async updateSelected() {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        this.all_selected = true;
        for await (const item of this.table_items[table_tab]) {
          if (!item.status) {
            this.all_selected = false;
          }
        }
      }
    },
    getItemTotal(index) {
      const totalAmount = [];
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        const { total, add_ons } = this.table_items[table_tab][index];
        totalAmount.push(total);
        if (add_ons && add_ons.length) {
          for (const add_on of add_ons) {
            totalAmount.push(add_on.total);
          }
        }
      }
      return this.formatCurrency(sum(totalAmount));
    },
    addItemAddOn(row) {
      if (row.type == "item_add_on") {
        const table_tab = this.bill_tab;
        if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
          for (let i = 0; i < this.table_items[table_tab].length; i++) {
            if (this.table_items[table_tab][i].status) {
              if (row.category == "remark") {
                this.remark_block = true;
                this.keyboardString(this.remark, "remark");
              } else {
                const index = findIndex(this.table_items[table_tab][i].add_ons, { id: row.id });
                if (index >= 0) {
                  const { quantity } = this.table_items[table_tab][i].add_ons[index];
                  const newQuantity = toSafeInteger(quantity) + 1;
                  const maxQuantity = toSafeInteger(row.max_quantity);
                  if (newQuantity <= maxQuantity) {
                    this.table_items[table_tab][i].add_ons[index].quantity = newQuantity;
                    this.table_items[table_tab][i].add_ons[index].total = round(
                      row.amount * newQuantity,
                      2
                    );
                  }
                } else {
                  this.table_items[table_tab][i].add_ons.push({
                    id: row.id,
                    name: row.name,
                    amount: row.amount,
                    quantity: 1,
                    total: row.amount,
                  });
                }
              }
            }
          }
        }
      } else if (row.type == "table_add_on") {
        if (row.category == "remark") {
          this.order_remark_block = true;
          this.keyboardString(this.order_remark, "order_remark");
        } else {
          const index = findIndex(this.table_add_ons, { id: row.id });
          if (index >= 0) {
            const { quantity } = this.table_add_ons[index];
            const newQuantity = toSafeInteger(quantity) + 1;
            const maxQuantity = toSafeInteger(row.max_quantity);
            if (newQuantity <= maxQuantity) {
              this.table_add_ons[index].quantity = newQuantity;
              this.table_add_ons[index].total = round(row.amount * newQuantity, 2);
            }
          } else {
            this.table_add_ons.push({
              id: row.id,
              name: row.name,
              amount: row.amount,
              quantity: 1,
              total: row.amount,
            });
          }
        }
      }
    },
    totalAmount() {
      const total = [];

      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        for (let i = 0; i < this.table_items[table_tab].length; i++) {
          total.push(this.table_items[table_tab][i].total);
          const add_ons = this.table_items[table_tab][i].add_ons;
          if (add_ons && add_ons.length) {
            for (const add_on of add_ons) {
              total.push(add_on.total);
            }
          }
        }
      }

      for (let z = 0; z < this.table_add_ons.length; z++) {
        total.push(this.table_add_ons[z].total);
      }

      return this.formatCurrency(sum(total));
    },
    getItemByCategory(category) {
      this.category = category;
      this.items = [];
      this.add_ons = [];
      this.item_loading = true;
      this.$nextTick(() => {
        this.getItems();
      });
    },
    updateQty(index, type) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        const item = this.table_items[table_tab][index];
        if (item) {
          if (type == "increase") {
            item.quantity = toSafeInteger(item.quantity) + 1;
            item.total = round(item.amount * item.quantity, 2);
            this.table_items[table_tab].splice(index, 1, item);
          } else if (type == "decrease") {
            item.quantity = toSafeInteger(item.quantity) - 1;
            if (item.quantity <= 0) {
              this.table_items[table_tab].splice(index, 1);
            } else {
              item.total = round(item.amount * item.quantity, 2);
              this.table_items[table_tab].splice(index, 1, item);
            }
          }
        }
      }
    },
    addItem(item) {
      const { id } = item;
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        const index = findIndex(this.table_items[table_tab], { id });
        if (index >= 0) {
          const item = this.table_items[table_tab][index];
          if (item) {
            item.quantity = toSafeInteger(item.quantity) + 1;
            this.table_items[table_tab].splice(index, 1, item);
          } else {
            this.pushItem(item);
          }
        } else {
          this.pushItem(item);
        }
      }
    },
    pushItem({ id, barcode, name_en, final_amount }) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        this.table_items[table_tab].push({
          id: id,
          barcode: barcode,
          status: true,
          remark: null,
          name: name_en,
          gift: null,
          add_ons: [],
          quantity: 1,
          discount_type: null,
          discount_value: null,
          discount_amount: null,
          category: this.table.category,
          amount: final_amount,
          total: final_amount,
        });
      }
    },
    searchItems() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.item_loading = true;
      _this.items = [];
      _this.add_ons = [];
      _this.timeout = setTimeout(function () {
        _this.getItems();
      }, _this.timeoutInterval);
    },
    getItems() {
      const filter = new Object();

      if (this.search_item) {
        filter.search = this.search_item;
      }

      if (this.category) {
        filter[this.category.type] = this.category.name;
        if (this.category.type == "add_on_category") {
          filter["add_on"] = 1;
        }
      }

      GET_ITEMS(filter)
        .then((data) => {
          if (filter.add_on) {
            this.add_ons = data;
          } else {
            this.items = data;
          }
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.item_loading = false;
        });
    },
    updateTable() {
      const _this = this;
      return new Promise((resolve, reject) => {
        UPDATE_TABLE_ITEMS({
          table: this.table.id,
          customer: this.customer.id,
          remark: this.table_order_remark,
          add_ons: this.table_add_ons,
          extra_fees: this.table_extra_fees,
          discount_level: this.discountTypeTab,
          discount_type: this.discountTab,
          discount_value: this.discountValue,
          walk_in: Number(this.walkInCustomer),
          gift: this.gift,
          item: this.table_items,
        })
          .then((data) => {
            _this.initTable(data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    pushToCheckout() {
      this.formLoading = true;

      this.updateTable()
        .then(({ table }) => {
          this.$router.push({
            name: "pos-checkout",
            query: { table: table.id, t: new Date().getTime() },
          });
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    nextFocus() {
      console.log("success");
    },
    getTable() {
      GET_TABLE_ITEMS(this.tableId)
        .then((data) => {
          this.initTable(data);
          this.initPOSOrder();
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
          this.goBack();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    initTable({ table, customer, items, bill_count }) {
      this.table = table;
      this.customer = customer;

      this.discountTypeTab = table.discount_level ? table.discount_level : "order";
      this.discountTab = table.discount_type ? table.discount_type : "percentage";
      this.discountValue = toNumber(table.discount_value);
      this.walkInCustomer = !!+table.walk_in;

      this.gift = table.gift;

      if (table.remark) {
        this.table_order_remark = table.remark;
      }

      this.table_extra_fees = [];

      if (table.extra_fees && table.extra_fees.length) {
        for (let i = 0; i < table.extra_fees.length; i++) {
          this.table_extra_fees.push({
            db_id: table.extra_fees[i].id,
            amount: table.extra_fees[i].amount,
            description: table.extra_fees[i].description,
            id: table.extra_fees[i].extra_fee,
            name: table.extra_fees[i].name,
          });
        }
      }

      this.table_add_ons = [];

      if (table.add_ons && table.add_ons.length) {
        for (let z = 0; z < table.add_ons.length; z++) {
          this.table_add_ons.push({
            db_id: table.add_ons[z].id,
            amount: table.add_ons[z].amount,
            id: table.add_ons[z].add_on,
            name: table.add_ons[z].name,
            quantity: table.add_ons[z].quantity,
            total: table.add_ons[z].total_amount,
          });
        }
      }

      if (bill_count) {
        this.bill_count = new Object();
        for (let i = 0; i < bill_count.length; i++) {
          this.bill_count[bill_count[i].bill] = bill_count[i].total_bill;
        }
      }

      this.bill_split = !!+table.split;

      this.table_items = new Object({
        table_tab_1: [],
        table_tab_2: [],
        table_tab_3: [],
      });

      if (items.length) {
        for (let z = 0; z < items.length; z++) {
          const table_tab = `table_tab_${items[z].bill}`;
          if (!isArray(this.table_items[table_tab])) {
            this.table_items[table_tab] = [];
          }
          this.table_items[table_tab].push(items[z]);
        }
      }

      this.keyboardClose();
    },
    initPOSOrder() {
      const item_category = this.getConfig("item_category");
      this.item_category = item_category;
      const first_item_category = head(item_category);
      if (first_item_category) {
        this.getItemByCategory(first_item_category);
      } else {
        this.getItems();
      }
    },
  },
  created() {
    this.tableId = toSafeInteger(this.$route.query.table);
  },
  mounted() {
    if (this.tableId > 0) {
      this.getTable();
    } else {
      this.goBack();
    }
  },
  components: {
    Keyboard,
  },
};
</script>
